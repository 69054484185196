import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { ROUTES } from '../../utils/AppRoutes';
import AccentButton from './AccentButton';

/**
 *
 * @param {object} sx style override for the button
 * @param {object} hoverStyle style override for the buttons hover style
 * @param {boolean} fullWidth true if the button should take the full width of the container
 * @param {string} label text to render instead of the default
 * @param {string} color MUI standard colors to render the button in e.g.: 'primary', 'secondary'...
 * @param withRoute
 * @param props
 * @returns
 */
function SignUpButton({
  sx,
  hoverStyle,
  fullWidth,
  label,
  color = 'primary',
  href = ROUTES.SIGNUP,
  ...props
}) {
  const { t } = useTranslation('common');
  return (
    <AccentButton
      color={color}
      href={href}
      size="large"
      sx={{
        minWidth: '18rem',
        margin: '3rem 0',
        height: 64,
        ':hover': !!hoverStyle && hoverStyle,
        ...sx,
      }}
      fullWidth={fullWidth}
      {...props}
    >
      <Typography fontWeight="bold">
        {label ? label : t('Jetzt kaufen')}
      </Typography>
    </AccentButton>
  );
}

export default SignUpButton;
