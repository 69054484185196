import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';

import { LIST_TRANSACTION_FRAGMENT } from '../../apollo/typeDefs';
import { ROUTES } from '../../utils/AppRoutes';

export const GET_TRANSACTION_PAGE = gql`
  query transactions(
    $filters: [TransactionFilter]
    $order: TransactionOrder
    $page: Int
  ) {
    transactions(filters: $filters, order: $order, page: $page) {
      ...ListTransaction
    }
  }
  ${LIST_TRANSACTION_FRAGMENT}
`;

export const GET_PAGES = gql`
  query transactionPages($filters: [TransactionFilter]) {
    transactionPages(filters: $filters)
  }
`;

export const TransactionFilterContext = createContext({});
export const TransactionFilterProvider = ({ children }) => {
  const router = useRouter();

  const [page, setPage] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [types, setTypes] = useState([]);
  const [excludeTypes, setExcludeTypes] = useState(false);
  const [assets, setAssets] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [onlyWarnings, setOnlyWarnings] = useState(false);
  const [onlyComments, setOnlyComments] = useState(false);
  const [order, setOrder] = useState({
    property: 'TRADE_DATE',
    ascending: false,
  });

  const [variables, setTransactionsQueryVariables] = useState({});
  const [init, transactionsQuery] = useLazyQuery(GET_TRANSACTION_PAGE, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const [initPage, pageQuery] = useLazyQuery(GET_PAGES, {
    variables: { filters: variables.filters },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (variables.filters && variables.page !== page)
      setTransactionsQueryVariables({ ...variables, page });
  }, [page]);

  useEffect(() => {
    const filters = [];

    if (dateRange?.[0])
      filters.push({
        property: 'TRADE_DATE',
        equation: 'GTE',
        values: [
          moment(dateRange[0])
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toISOString(),
        ],
      });
    if (dateRange?.[1])
      filters.push({
        property: 'TRADE_DATE',
        equation: 'LTE',
        values: [
          moment(dateRange[1])
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toISOString(),
        ],
      });

    if (types.length > 0)
      filters.push({
        property: 'TYPE',
        values: types.map((t) => t.key),
        equation: excludeTypes ? 'NIN' : 'IN',
      });
    if (wallets.length > 0)
      filters.push({ property: 'WALLET', values: wallets.map((t) => t._id) });
    if (assets.length > 0)
      filters.push({ property: 'ASSET', values: assets.map((w) => w._id) });
    if (onlyWarnings) filters.push({ property: 'WARNINGS', values: ['true'] });
    if (onlyComments) filters.push({ property: 'COMMENTS', values: ['true'] });

    setTransactionsQueryVariables({ order, filters, page: 0 });
    setPage(0);
  }, [
    types,
    dateRange,
    wallets,
    assets,
    onlyWarnings,
    onlyComments,
    order,
    excludeTypes,
  ]);

  return (
    <TransactionFilterContext.Provider
      value={{
        dateRange,
        setDateRange,
        types,
        setTypes,
        setExcludeTypes,
        assets,
        setAssets,
        wallets,
        setWallets,
        onlyWarnings,
        setOnlyWarnings,
        onlyComments,
        setOnlyComments,
        order,
        setOrder,

        init: () => {
          init();
          initPage();
        },
        transactionsQuery,
        page,
        pages: pageQuery?.data?.transactionPages ?? 0,
        setPage,

        goToWarnings: (newDateRange = []) => {
          setWallets([]);
          setTypes([]);
          setAssets([]);
          setDateRange(newDateRange);
          setOnlyWarnings(true);
          setOrder({ property: 'TRADE_DATE', ascending: false });
          setTimeout(() => router.push(ROUTES.TRANSACTIONS), 200);
        },
        goToWallet: (wallet) => {
          setWallets([wallet]);
          setTypes([]);
          setAssets([]);
          setDateRange([]);
          setOnlyWarnings(false);
          setOrder({ property: 'TRADE_DATE', ascending: false });
          setTimeout(
            () => router.push(`${ROUTES.TRANSACTIONS}?from=${wallet._id}`),
            200
          );
        },
      }}
    >
      {children}
    </TransactionFilterContext.Provider>
  );
};
