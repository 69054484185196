import { gql, useMutation } from '@apollo/client';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useContext, useRef, useState } from 'react';

import CSelect from './structure/Select';
import { UsersContext } from './hooks/useUser';
import { regions } from '@cr/common/src/config/constants';

const CHANGE_TAX_REGION = gql`
  mutation changeTaxRegion($taxRegion: String!) {
    changeTaxRegion(taxRegion: $taxRegion)
  }
`;

function ChangeTaxRegion({ variant, color = 'light' }) {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const { user } = useContext(UsersContext);
  const regionsAsArray = Object.entries(regions);

  const isLightColor = color === 'light';

  const [changeTaxRegionMutation] = useMutation(CHANGE_TAX_REGION, {
    onError: (error) =>
      enqueueSnackbar(
        t(
          'Deine Steuer-Region konnte nicht gespeichert werden. Bitte versuche es später nochmal.'
        ),
        { variant: 'error' }
      ),
  });

  const changeTaxRegion = async (taxRegion) => {
    setIsOpen(false);
    await router.push(router.asPath, undefined, { taxRegion: taxRegion });
    if (user) {
      await changeTaxRegionMutation({
        variables: { taxRegion },
        // update the cache for the user object if successful
        update: (cache, { data: { changeTaxRegion } }) => {
          if (!changeTaxRegion) return;
          cache.modify({
            id: cache.identify(user),
            fields: {
              taxRegion: () => taxRegion,
            },
          });
        },
      });
      await router.push('/dashboard');
    }
  };

  switch (variant) {
    case 'select':
      return (
        <FormControl>
          <Select
            fullWidth
            labelId="change-taxRegion-select"
            value={user.taxRegion}
            onChange={(event) => changeTaxRegion(event.target.value)}
          >
            {regionsAsArray.map(([, region], index) => (
              <MenuItem key={index} value={region.key}>
                {region.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );

    case 'select-small':
      return (
        <CSelect
          value={user.taxRegion}
          renderValue={(value) => (
            <Typography
              fontWeight="bold"
              color={isLightColor ? 'custom.black' : 'custom.white'}
            >
              {
                regionsAsArray.find(([, region]) => region.key === value)[1]
                  .short
              }
            </Typography>
          )}
          onChange={(event) => changeTaxRegion(event.target.value)}
          sx={{
            backgroundColor: !isLightColor && '#131313',
          }}
          iconStroke={isLightColor ? 'custom.black' : '#FFBF2C'}
        >
          {regionsAsArray.map(([, region], index) => (
            <MenuItem key={index} value={region.key}>
              <Typography fontWeight="bold">{region.short}</Typography>
            </MenuItem>
          ))}
        </CSelect>
      );

    case 'select-fullwidth':
      return (
        <CSelect
          value={user.taxRegion}
          renderValue={(value) => (
            <Typography
              fontWeight="bold"
              color={isLightColor ? 'custom.black' : 'custom.white'}
            >
              {
                regionsAsArray.find(([, region]) => region.key === value)[1]
                  .short
              }
            </Typography>
          )}
          onChange={(event) => changeTaxRegion(event.target.value)}
          sx={{
            width: '100vw',
            height: '2rem',
            backgroundColor: !isLightColor && '#131313',
            borderRadius: 0,
          }}
          menuProps={
            isMobile
              ? {
                  PopoverClasses: {
                    paper: 'change-taxRegion-override-paper',
                  },
                }
              : {}
          }
          iconStroke={isLightColor ? 'custom.black' : '#FFBF2C'}
        >
          {regionsAsArray.map(([, region], index) => (
            <MenuItem key={index} value={region.key}>
              <Typography fontWeight="bold">{region.short}</Typography>
            </MenuItem>
          ))}
        </CSelect>
      );

    default:
      return (
        <>
          <div ref={menuRef}>
            <IconButton
              onClick={() => setIsOpen(true)}
              color="inherit"
              size="large"
            >
              <TranslateIcon />
            </IconButton>
          </div>

          <Menu
            open={isOpen}
            onClose={() => setIsOpen(false)}
            anchorEl={menuRef.current}
          >
            {regionsAsArray.map(([, region], index) => {
              const islastOption = regionsAsArray.length === index + 1;

              return (
                <span key={index}>
                  <MenuItem
                    onClick={() => changeTaxRegion(region.key)}
                    selected={user.taxRegion === region.key}
                  >
                    <ListItemText primary={region.label} />
                  </MenuItem>

                  {!islastOption && <Divider />}
                </span>
              );
            })}
          </Menu>
        </>
      );
  }
}

export default ChangeTaxRegion;
