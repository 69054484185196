import {
  Checkbox,
  InputAdornment,
  TextField as MuiTextField,
  styled,
} from '@mui/material';
import React from 'react';

const AdornmentCheckbox = ({ checkBox, ...checkBoxProps }) => {
  return (
    <InputAdornment position={checkBox}>
      <Checkbox {...checkBoxProps} />
    </InputAdornment>
  );
};

/**
 * A MUI styled Textfield/Select
 * 'checkBox': 'start' | 'end' => appends checkbox adornement
 * 'checkBoxProps': props to be spread to the checkbox
 */
function CTextField({
  label,
  placeholder,
  checkBox,
  checkBoxProps,
  ...inputProps
}) {
  return (
    <MuiTextField
      InputLabelProps={{ sx: { fontWeight: 900 } }}
      label={label}
      placeholder={placeholder ?? label}
      {...inputProps}
      InputProps={{
        startAdornment: checkBox === 'start' && (
          <AdornmentCheckbox
            checkBox={checkBox}
            checkBoxProps={checkBoxProps}
          />
        ),
        endAdornment: checkBox === 'end' && (
          <AdornmentCheckbox
            checkBox={checkBox}
            checkBoxProps={checkBoxProps}
          />
        ),
        ...inputProps.InputProps,
      }}
    />
  );
}

export default styled(CTextField)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
