import { Box, ButtonBase, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import BrandLogo from '../../media/icons/brand-logomark.svg';
import menu from '../../menu';
import AccentButton from './AccentButton';

const TabMenu = ({ isDesktop, isMobile }) => {
  const { t } = useTranslation('common');
  const [tabValueIndex, setTabValueIndex] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!router || !router.pathname) return;

    const newTabValueIndex = menu.findIndex((item) =>
      router.pathname.includes(item.path)
    );
    setTabValueIndex(newTabValueIndex < 0 ? false : newTabValueIndex);
  }, [router.asPath]);

  if (isDesktop) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {menu.map((item, index) => {
          const isActive = index === tabValueIndex;
          const MenuIcon = item.icons[0];
          const ActiveMenuIcon = item.icons[1];

          return (
            <Grid item key={index}>
              <AccentButton
                href={menu[index].path}
                variant="text"
                size="small"
                style={{ padding: 11 }}
                startIcon={
                  isActive ? (
                    <ActiveMenuIcon style={{ height: 24, width: 24 }} />
                  ) : (
                    <MenuIcon style={{ height: 24, width: 24 }} />
                  )
                }
              >
                {t(item.name)}
                {isActive && (
                  <Box
                    sx={{
                      width: '32px',
                      height: '32px',
                      position: 'absolute',
                      top: 40,
                      left: 0,
                      right: 0,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      borderRadius: 25,
                      border: ({ palette }) =>
                        `13px solid ${palette.primary.Q}`,
                      backgroundColor: item.color,
                    }}
                  />
                )}
              </AccentButton>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
      {!isMobile && (
        <>
          <Box
            style={{
              width: '400px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BrandLogo style={{ height: 24, width: 24 }} />
          </Box>
          <Divider orientation="vertical" flexItem />
        </>
      )}
      {menu.map((item, index) => {
        const isActive = index === tabValueIndex;
        const MenuIcon = item.icons[0];
        const ActiveMenuIcon = item.icons[1];

        return (
          <Box
            key={index}
            style={{
              flexBasis: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NextLink href={menu[index].path} passHref>
              <ButtonBase component="a">
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ position: 'relative' }}
                >
                  <Grid item>
                    {isActive ? (
                      <ActiveMenuIcon style={{ height: 24, width: 24 }} />
                    ) : (
                      <MenuIcon style={{ height: 24, width: 24 }} />
                    )}
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        fontSize: '8px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t(item.name)}
                    </Box>
                  </Grid>
                  {isActive && (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: 12,
                        height: 12,
                        top: -19,
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: 2,
                        borderRadius: 3,
                        borderColor: 'primary.Q',
                        backgroundColor: item.color,
                      }}
                    />
                  )}
                </Grid>
              </ButtonBase>
            </NextLink>
          </Box>
        );
      })}
    </Box>
  );
};

export default TabMenu;
