import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useState } from 'react';

import { getPublicPageStatus } from '../../utils/AppRoutes';
import ChangeLanguage from '../ChangeLanguage';
import ChangeTaxRegion from '../ChangeTaxRegion';
import ChangeEmail from '../settings/ChangeEmail';
import ChangeFirstname from '../settings/ChangeFirstname';
import ChangePassword from '../settings/ChangePassword';
import RequestAccountRemoval from '../settings/RequestAccountRemoval';
import AccentButton from '../structure/AccentButton';
import { UsersContext } from './useUser';

export const SettingsContext = createContext({});
export const SettingsProvider = ({ children }) => {
  const { t } = useTranslation(['common']);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const router = useRouter();
  const isPublicPage = getPublicPageStatus(router.pathname);

  const { user, logout } = useContext(UsersContext);
  const [open, setOpen] = useState(false);

  return (
    <SettingsContext.Provider value={{ setOpen, open }}>
      {children}

      {!isPublicPage && (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          anchor={isMobile ? 'bottom' : 'right'}
          sx={
            !isMobile
              ? {
                  '& .MuiDrawer-paper': {
                    width: 420,
                    padding: '4rem 1.5rem 1.5rem 2rem',
                  },
                }
              : { '& .MuiDrawer-paper': { height: '80%' } }
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h2" sx={{ mb: 4 }}>
              {t('structure:Einstellungen')}
            </Typography>

            {user?.isAdmin && (
              <AccentButton href="/admin" size="large">
                Admin
              </AccentButton>
            )}

            {!user?.isAdmin &&
              user?.taxAdvising &&
              user?.taxAdvising.length > 0 && (
                <AccentButton href="/admin" size="large">
                  Tax Advisor
                </AccentButton>
              )}

            <Typography variant="h4">{t('common:Sprache')}</Typography>
            <ChangeLanguage variant="select" />

            <Divider />

            <Typography variant="h4">{t('common:Steuer-Region')}</Typography>
            <ChangeTaxRegion variant="select" />

            <Divider />
            <ChangeEmail />

            <Divider />
            <ChangeFirstname />

            <Divider />
            <ChangePassword />

            <Divider />
            <RequestAccountRemoval />

            <Divider />
            <AccentButton
              onClick={async () => {
                setOpen(false);
                await logout();
              }}
              size="large"
            >
              {t('structure:Logout')}
            </AccentButton>
          </Box>
        </Drawer>
      )}
    </SettingsContext.Provider>
  );
};
