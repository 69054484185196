import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import getConfig from 'next/config';
import { useMemo } from 'react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import fetch from 'node-fetch';
import { tokenKey } from '@cr/common/src/config/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const { publicRuntimeConfig } = getConfig();

function createApolloClient(ctx = null) {
  const isServer = typeof window === 'undefined';
  const enhancedFetch = (url, init) => {
    let token;
    try {
      token = JSON.parse(localStorage.getItem(tokenKey));
    } catch (e) {
      console.log('Unable to parse token', e);
      localStorage.removeItem(tokenKey);
    }
    return fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        ...(token ? { Authorization: token } : {}),
      },
    }).then((response) => response);
  };

  const httpLink = createUploadLink({
    uri: publicRuntimeConfig.graphUrl,
    fetch: enhancedFetch,
  });

  const link = from([
    onError(({ graphQLErrors, networkError }) => {
      // FIXME There was something we should revert restrictions/permissions again?
      if (graphQLErrors) {
        console.error('Apollo.index: graphQL errors occurred:');
        console.dir(graphQLErrors, { depth: null });
        if (
          graphQLErrors.some(
            (item) => item.extensions.code === 'UNAUTHENTICATED'
          )
        ) {
          // Reset cache if user is unauthenticated. This avoids stale user from being
          // saved which in turn makes the UI think a user is still existent and all request would fail
          apolloClient.cache.reset();
        }
      }
      if (networkError) {
        console.error(
          'Apollo.index error logging: ' +
            'Graph URL: ' +
            publicRuntimeConfig.graphUrl +
            ' stage: ' +
            publicRuntimeConfig.stage
        );
        console.error(
          'Apollo.index: A network error occurred: ' + networkError
        );
        console.dir(networkError, { depth: null });
      }
    }),
    httpLink,
  ]);

  return new ApolloClient({
    ssrMode: isServer,
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getRefreshActions: {
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
        Wallet: {
          fields: {
            api: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
      },
    }),
  });
}

let apolloClient;
export function initializeApollo({ initialState = null, ctx = null } = {}) {
  const _apolloClient =
    apolloClient ??
    createApolloClient(typeof window === 'undefined' ? ctx : null);

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore(
      merge(initialState, existingCache, {
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) =>
            sourceArray.every((s) => !isEqual(d, s))
          ),
        ],
      })
    );
  }

  if (typeof window === 'undefined') return _apolloClient;
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo({ initialState: state }), [state]);
}
