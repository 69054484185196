import { locals, regions } from './config/constants.js';

export const areRequired = (values, errors, keys, t) => {
  for (const key of keys) if (!values[key]) errors[key] = t('Notwendig.');
};

export const checkPasswordLength = (password, errors, t) => {
  if (password.length < 5)
    errors.password = t('Das Passwort muss aus mindestens 5 Zeichen bestehen.');
  else if (password.length > 40)
    errors.password = t(
      'Das Passwort sollte nicht länger als 40 Zeichen sein.'
    );
};

export const maxCharacterLength = (values, errors, checks, t) => {
  for (const check of checks) {
    if (values[check.key] && values[check.key].length > check.length)
      errors[check.key] = ` ${values[check.key].length}  /  ${
        check.length
      }  ${t('maximale Zeichenlänge erreicht.')}`;
  }
};

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmailValid = (_email) => {
  if (!_email) return false;
  const email = _email.trim().toLowerCase();
  return emailRegex.test(email);
};

export const isValidLocale = (language) =>
  Object.entries(locals).some(([_, entry]) => entry.key === language);

export const isValidTaxRegion = (region) =>
  Object.entries(regions).some(([_, entry]) => entry.key === region);

export const isValidFirstname = (firstname) => firstname.length > 0;
