import { useLazyQuery, useQuery } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslation } from 'next-i18next';
import { useMediaQuery, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePlausible } from 'next-plausible';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';

import { tokenKey } from '@cr/common/src/config/constants';
import { isValidLocale } from '@cr/common/src/validation';
import {
  GET_TRANSACTIONS,
  GET_USER,
  GET_WALLET,
  GET_WALLETS_LITE,
  GET_REFRESH_ACTIONS,
} from '../../apollo/typeDefs';
import { ROUTES, getPublicPageStatus } from '../../utils/AppRoutes';
import useLocalStorage from './useLocalStorage';
import SpinnerLight from '../../media/spinnerLight.svg';
import IconButton from '../IconButton';
import CloseIcon from '../../media/icons/ic-actions-close-simple.svg';

// FIXME: is 5000 really a good idea?
const POLLING_INTERVAL = 1000;

export const SUB_TOPICS = {
  USER_CALCULATING: {
    name: 'USER_CALCULATING',
    graphKey: 'userCalculating',
  },
  REFETCH_TRANSACTIONS: {
    name: 'REFETCH_TRANSACTIONS',
    graphKey: 'refetchTransactions',
  },
  REFETCH_WALLET: {
    name: 'REFETCH_WALLET',
    graphKey: 'refetchWallet',
  },
  WALLET_API_STATUS_UPDATED: {
    name: 'WALLET_API_STATUS_UPDATED',
    graphKey: 'walletApiStatusUpdated',
  },
  REPORT_DOWNLOAD_URL: {
    name: 'REPORT_DOWNLOAD_URL',
    graphKey: 'reportDownloadUrl',
  },
};

const { publicRuntimeConfig } = getConfig();

export const UsersContext = createContext({});
export const UsersProvider = ({ children }) => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const plausible = usePlausible();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [user, setUser] = useState(null);
  const [afterLogout, setAfterLogout] = useState(false);
  const [isLightVariant, setIsLightVariant] = useState(true);
  const [taxStatsErrorSnack, setTaxStatsErrorSnack] = useState(null);
  const apolloClient = useApolloClient();
  const router = useRouter();

  const [token, setToken, removeToken] = useLocalStorage(tokenKey, null);
  const [prevUserToken, setPrevUserToken] = useLocalStorage(
    'prevUserToken',
    null
  );

  const {
    loading: loadingUser,
    data: userData,
    refetch: refetchUser,
  } = useQuery(GET_USER, { skip: !token });

  const {
    data: refreshActionsData,
    startPolling: startPollingRefreshActions,
    stopPolling: stopPollingRefreshActions,
  } = useQuery(GET_REFRESH_ACTIONS, {
    fetchPolicy: 'network-only',
    pollInterval: POLLING_INTERVAL,
    notifyOnNetworkStatusChange: true,
    skip: !token || !user || !userData?.user?._id,
  });

  const [refetchTransactions] = useLazyQuery(GET_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
  });
  const [refetchWallet] = useLazyQuery(GET_WALLET, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [_, { data: walletsData }] = useLazyQuery(GET_WALLETS_LITE);

  async function login({
    token: newToken,
    afterLoginDestination = ROUTES.DASHBOARD,
    impersonate = false,
  }) {
    if (impersonate) {
      // set admin token to currently signed in user's token
      const currentAdminToken = token;
      setPrevUserToken(currentAdminToken);
    } else if (prevUserToken) setPrevUserToken(null);
    await cleanCache();

    setToken(newToken);

    const {
      data: { user: newUser },
    } = await refetchUser();
    if (!newUser) {
      throw new Error('User not loaded after login');
    }
    setUser(newUser);

    // forward to the dashboard
    if (newUser) {
      setAfterLogout(false);

      if (afterLoginDestination)
        await router.push(afterLoginDestination, null, {
          locale:
            newUser.locale && isValidLocale(newUser.locale)
              ? newUser.locale
              : 'en',
        });
      startPollingRefreshActions(POLLING_INTERVAL);
    }
  }

  async function goBackToPrevUser() {
    if (!prevUserToken) return;
    await login({ token: prevUserToken });
  }

  async function logout(forwardLandingPage = true) {
    setAfterLogout(true);
    if (forwardLandingPage) await router.push('/');
    await cleanCache();
    stopPollingRefreshActions();
  }

  async function cleanCache() {
    try {
      setUser(null);
      removeToken();
      await apolloClient.clearStore();
    } catch (e) {
      // some "unauthorized" errors are thrown because of the missing user key at this point...
    }
  }

  useEffect(() => {
    if (!loadingUser) {
      const newUser = userData?.user;
      if (newUser && !afterLogout) {
        setUser(newUser);
      } else if (
        !newUser &&
        !getPublicPageStatus(router.pathname) &&
        !router.pathname?.includes('sign-in?back=')
      ) {
        setUser(null);
        const encodedUri = encodeURI(router.pathname);
        router.push(`/sign-in?back=${encodedUri}`);
      }
    }
  }, [loadingUser, userData?.user]);

  useEffect(() => {
    if (!user || typeof window === 'undefined') return; // no ssr

    /* eslint-disable-next-line */
    if (
      window.Intercom &&
      publicRuntimeConfig.stage !== 'development' &&
      user.chatToken &&
      !prevUserToken
    ) {
      /* eslint-disable-next-line */
      window.Intercom('boot', {
        app_id: publicRuntimeConfig.intercomAppId,
        user_id: user._id,
        user_hash: user.chatToken,
        email: user.email.current,
        name: user.address?.firstname,
      });
    }

    // log opened session in plausible for better analysis
    if (!user.isAdmin && !user.isDemo) plausible('Session');
  }, [user?._id]);

  // React on data from polling
  useEffect(() => {
    if (!user) return;

    const handleActions = async () => {
      if (refreshActionsData) {
        const actions = refreshActionsData.getRefreshActions;
        for (const { key, value } of actions) {
          switch (key) {
            case SUB_TOPICS.USER_CALCULATING.name:
              const calcCode = parseInt(value); // -2 over 150 asset convs, -1 error, 0 false, 1 true
              const currentTaxRegion = user.taxRegion;
              setTimeout(() => {
                if (calcCode === 1) {
                  apolloClient.cache.modify({
                    id: apolloClient.cache.identify(user),
                    fields: {
                      calculating: () => true,
                      taxRegion: () => currentTaxRegion, // Halte die gespeicherte taxRegion
                    },
                  });
                } else {
                  apolloClient.refetchQueries({
                    include: [
                      'getUser',
                      'wallet',
                      'wallets',
                      'taxYears',
                      'getRefreshActions',
                    ],
                  });
                }

                if (calcCode < 0 && !taxStatsErrorSnack) {
                  setTaxStatsErrorSnack(
                    enqueueSnackbar(
                      value.toString() === '-2'
                        ? t('taxStats-asset-error')
                        : t('taxStats-error'),
                      {
                        persist: true,
                        variant: 'error',
                        action: (snackbarId) => (
                          <IconButton
                            size="small"
                            color="info"
                            variant="contained"
                            onClick={() => closeSnackbar(snackbarId)}
                          >
                            <CloseIcon
                              style={{ height: 20, width: 20, stroke: 'white' }}
                            />
                          </IconButton>
                        ),
                      }
                    )
                  );
                }
              }, 200);
              break;
            case SUB_TOPICS.REFETCH_TRANSACTIONS.name:
              const txIds = value.split(',');
              if (txIds.length < 1) {
                setTimeout(() => {
                  apolloClient.refetchQueries({ include: ['transactions'] });
                }, 200);
              } else {
                await refetchTransactions({ variables: { _ids: txIds } });
              }
              break;
            case SUB_TOPICS.REFETCH_WALLET.name:
              const walletId = value;
              await refetchWallet({ variables: { _id: walletId } });
              break;
            case SUB_TOPICS.REPORT_DOWNLOAD_URL.name:
              apolloClient.refetchQueries({ include: ['taxYears'] });

              const response = value;
              if (!response || response === 'error')
                enqueueSnackbar('Unknown error while PDF generation.', {
                  variant: 'error',
                });
              else
                enqueueSnackbar(t('Dein Download steht bereit!'), {
                  persist: true,
                  variant: 'info',
                  action: (snackbarId) => (
                    <>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{
                          height: 33.5,
                          marginRight: 4,
                          marginTop: isMobile ? 8 : 0,
                        }}
                        onClick={() => {
                          closeSnackbar(snackbarId);

                          const link = document.createElement('a');
                          link.href = value;
                          link.target = '_blank';
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      >
                        {t('Herunterladen')}
                      </Button>
                      <IconButton
                        size="small"
                        color="info"
                        variant="contained"
                        onClick={() => closeSnackbar(snackbarId)}
                      >
                        <CloseIcon
                          style={{ height: 20, width: 20, stroke: 'white' }}
                        />
                      </IconButton>
                    </>
                  ),
                });
              break;
          }
        }
      }
    };

    handleActions().catch((error) => {
      console.error('useUser: Failed to handle actions', error);
    });
  }, [loadingUser, refreshActionsData]);

  // hide intercom chat on mobile devices
  useEffect(() => {
    /* eslint-disable-next-line */
    if (window.Intercom) {
      /* eslint-disable-next-line */
      window.Intercom('update', {
        hide_default_launcher: Boolean(user) && isMobile,
      });
    }
  }, [user, isMobile]);

  // calculations snackbar
  const [runningJob, setRunningJob] = useState(null);
  useEffect(() => {
    let job = null;
    if (user) {
      if (walletsData?.wallets?.some((w) => w.api?.state === 'SYNCING'))
        job = 'job-syncing';
      else if (user.calculating) job = 'job-processing';
    }
    if (job !== runningJob) setRunningJob(job);
  }, [user, walletsData]);

  const [jobSnackbar, setJobSnackbar] = useState(null);
  useEffect(() => {
    if (!enqueueSnackbar) return;
    if (!runningJob && jobSnackbar) {
      closeSnackbar(jobSnackbar);
      setJobSnackbar(null);
    } else if (runningJob) {
      const newSnackbar = enqueueSnackbar(t(runningJob), {
        persist: true,
        variant: 'info',
        action: (snackbarId) => (
          <>
            <SpinnerLight
              style={{ height: 24, width: 24, margin: -5, marginRight: 5 }}
            />
            <IconButton
              size="small"
              color="info"
              variant="contained"
              onClick={() => closeSnackbar(snackbarId)}
            >
              <CloseIcon style={{ height: 20, width: 20, stroke: 'white' }} />
            </IconButton>
          </>
        ),
      });
      if (jobSnackbar) closeSnackbar(jobSnackbar);
      setJobSnackbar(newSnackbar);
    }
  }, [enqueueSnackbar, runningJob]);

  return (
    <UsersContext.Provider
      value={{
        isImpersonate: !!prevUserToken,
        user,
        loading: loadingUser,
        refetch: refetchUser,
        logout,
        login,
        goBackToPrevUser,
        isLightVariant,
        setIsLightVariant,
        runningJob,
        setRunningJob,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
