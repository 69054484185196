import { Alert, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';

import { UsersContext } from './hooks/useUser';

const CookieDisclaimer = () => {
  const { t } = useTranslation('common');

  const { user } = useContext(UsersContext);
  const [cookies, setCookie] = useCookies(['consent']);

  return (
    <Snackbar sx={{ maxWidth: 900 }} open={!user && !cookies.consent}>
      <Alert
        sx={{ backgroundColor: 'primary.main' }}
        icon={false}
        action={
          <Button
            color="inherit"
            style={{ height: 33.5, marginRight: 4 }}
            onClick={() => setCookie('consent', true)}
          >
            {t('Zustimmen')}
          </Button>
        }
      >
        <Typography variant="body2" sx={{ color: 'primary.J' }}>
          <Trans i18nKey="Wir verwenden Cookies" ns="components">
            Wir verwenden <b>Cookies</b>
            (auch von Drittanbietern), um einen uneingeschränkten Service zu
            gewährleisten. Detaillierte Informationen und wie die Verwendung von
            Cookies jederzeit widersprochen werden kann, findest Du in unseren
            Datenschutzhinweisen. Wenn Du fortfährst stimmst Du unserer Nutzung
            von Cookies zu.
          </Trans>
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default CookieDisclaimer;
