import { deDE, enUS } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { Inter } from 'next/font/google';
import React from 'react';

import { locals } from '@cr/common/src/config/constants';
import IcActionsInfo from './media/icons/ic-actions-info.svg';
import IcActionsMultiSuccess from './media/icons/ic-actions-multi-success.svg';
import IcActionsMulti from './media/icons/ic-actions-multi.svg';
import IcChevronDown from './media/icons/ic-chevron-down.svg';
import Spinner from './media/spinner.svg';

export const inter = Inter({
  subsets: ['latin'],
  fallback: ['arial'],
});

export const palette = {
  primary: {
    main: '#000000',
    B: '#101010',
    C: '#202020',
    D: '#303030',
    E: '#404040',
    F: '#505050',
    G: '#606060',
    H: '#707070',
    I: '#808080',
    J: '#8F8F8F',
    K: '#9F9F9F',
    L: '#AFAFAF',
    M: '#BFBFBF',
    N: '#CFCFCF',
    O: '#DFDFDF',
    P: '#EFEFEF',
    Q: '#F6F6F6',
  },
  secondary: {
    main: '#FFBF2C',
    B: '#FFBF2CCC',
    C: '#FFBF2C99',
    D: '#FFBF2C66',
    E: '#FFBF2C33',
    F: '#FFBF2C26',
    G: '#FFBF2C1A',
    H: '#FFBF2C12',
    I: '#FFBF2C0A',
  },
  info: {
    main: '#5A58FF',
    B: '#5A58FFCC',
    C: '#5A58FF99',
    D: '#5A58FF66',
    E: '#5A58FF33',
    F: '#5A58FF26',
    G: '#5A58FF1A',
    H: '#5A58FF12',
    I: '#5A58FF0A',
  },
  success: {
    main: '#1FCC79',
    B: '#1FCC79CC',
    C: '#1FCC7999',
    D: '#1FCC7966',
    E: '#1FCC7933',
    F: '#1FCC7926',
    G: '#1FCC791A',
    H: '#1FCC7912',
    I: '#1FCC790A',
  },
  error: {
    main: '#FF5E2C',
    B: '#FF5E2CCC',
    C: '#FF5E2C99',
    D: '#FF5E2C66',
    E: '#FF5E2C66',
    F: '#FF5E2C26',
    G: '#FF5E2C1A',
    H: '#FF5E2C12',
    I: '#FF5E2C0A',
  },
  custom: {
    background: '#F8F8F8',
    white: '#ffffff',
    black: '#000000',
    deFiChain: '#FF00AF',
  },
};

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const fontWeights = {
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightBold: 700,
};

const fontSizes = {
  desktop: {
    // Bold font weigth
    header1: 32,
    header2: 28,
    header3: 24,
    header4: 18,
    header5: 15,
    header6: 13,

    // Medium font weight
    variant1: 16,
    variant2: 14,
    variant3: 12,
  },

  mobile: {
    // Bold font weigth
    header1: 28,
    header2: 24,
    header3: 22,
    header4: 18,
    header5: 14,
    header6: 10,

    // Medium font weight
    variant1: 15,
    variant2: 12,
    variant3: 10,
  },
};

const typography = {
  body1: {
    fontSize: fontSizes.desktop.variant1,
    fontWeight: fontWeights.fontWeightRegular,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.variant1,
    },
  },
  body2: {
    fontSize: fontSizes.desktop.variant2,
    fontWeight: fontWeights.fontWeightRegular,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.variant2,
    },
  },
  subtitle1: {
    fontSize: fontSizes.desktop.variant3,
    fontWeight: fontWeights.fontWeightRegular,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.variant3,
    },
  },
  subtitle2: {
    color: palette.primary.L,
    fontSize: fontSizes.desktop.variant3,
    fontWeight: fontWeights.fontWeightRegular,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.variant3,
    },
  },
  h1: {
    fontSize: fontSizes.desktop.header1,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header1,
    },
  },
  h2: {
    fontSize: fontSizes.desktop.header2,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header1,
    },
  },
  h3: {
    fontSize: fontSizes.desktop.header3,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header3,
    },
  },
  h4: {
    fontSize: fontSizes.desktop.header4,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header4,
    },
  },
  h5: {
    fontSize: fontSizes.desktop.header5,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header5,
    },
  },
  h6: {
    fontSize: fontSizes.desktop.header6,
    fontWeight: fontWeights.fontWeightBold,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: fontSizes.mobile.header6,
    },
  },
  fontFamily: inter.style.fontFamily,
  ...fontWeights,
};

export const buildTheme = (locale, mode = 'light') => {
  return createTheme(
    {
      spacing: 8,
      shape: { borderRadius: 6 },
      palette: { ...palette, mode },
      shadows: Array(25).fill('none'),
      typography,
      breakpoints,
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true, // No more ripple, on the whole application
          },
        },
        MuiButton: {
          styleOverrides: {
            root: { borderRadius: 6, textTransform: 'none' },
            sizeSmall: { ...typography.h6 },
            sizeMedium: {
              padding: '5.75px 24px 5.75px 24px',
              ...typography.h5,
              fontSize: 14,
            },
            sizeLarge: {
              padding: '15px 32px 15px 32px',
              height: 48,
              ...typography.h5,
            },
            containedPrimary: {
              color: palette.primary.S,
              backgroundColor: palette.custom.main,
              '&:hover': { backgroundColor: palette.primary.main },
            },
            containedSecondary: {
              color: palette.primary.main,
              backgroundColor: palette.primary.P,
              '&:hover': { backgroundColor: palette.primary.O },
            },
            containedWarning: {
              backgroundColor: palette.error.main,
              color: palette.custom.white,
              '&:hover': { backgroundColor: palette.error.B },
            },
            containedInfo: {
              backgroundColor: palette.info.main,
              color: palette.custom.white,
              '&:hover': { backgroundColor: palette.info.B },
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              lineHeight: 0,
              borderRadius: 6,
              '&.Mui-disabled': { backgroundColor: palette.primary.O },
            },
            sizeSmall: { padding: 6.75 },
            sizeMedium: { padding: 11 },
            sizeLarge: { padding: 14 },
            colorPrimary: {
              backgroundColor: palette.primary.main,
              '&:hover': { backgroundColor: palette.primary.main },
            },
            colorSecondary: {
              backgroundColor: palette.primary.P,
              '&:hover': { backgroundColor: palette.primary.O },
              color: palette.primary.main,
            },
            colorWarning: {
              backgroundColor: palette.error.main,
              '&:hover': { backgroundColor: palette.error.B },
            },
          },
        },
        MuiLoadingButton: {
          defaultProps: {
            loadingIndicator: <Spinner style={{ height: 24, width: 24 }} />,
          },
        },
        MuiCard: {
          styleOverrides: {
            root: { backgroundColor: '#FFFFFF', borderRadius: 6 },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              paddingTop: 26,
              paddingBottom: 11,
              paddingLeft: 24,
              paddingRight: 24,
              [`@media (max-width: ${breakpoints.sm}px)`]: {
                paddingTop: 21,
                paddingBottom: 11,
                paddingLeft: 16,
                paddingRight: 16,
              },
            },
            title: { ...typography.h4 },
            subheader: { ...typography.subtitle2 },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              paddingTop: 18,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              [`@media (max-width: ${breakpoints.sm}px)`]: {
                paddingTop: 10,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                '&:last-child': { paddingBottom: 16 },
              },
            },
          },
        },
        MuiCardActions: {
          styleOverrides: {
            root: {
              paddingTop: 0,
              paddingBottom: 16,
              paddingLeft: 24,
              paddingRight: 24,
              [`@media (max-width: ${breakpoints.sm}px)`]: {
                paddingLeft: 16,
                paddingRight: 16,
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            sizeMedium: {
              borderRadius: 6,
              fontSize: 13,
              fontWeight: fontWeights.fontWeightBold,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: { 'label + &': { marginTop: 10 } },
            input: { padding: '9px 12px !important' },
            inputSizeSmall: { padding: '6px 12px !important' },
            inputAdornedStart: { paddingLeft: '0 !important' },
            inputMultiline: { padding: '0 !important' },
          },
        },
        MuiInputLabel: {
          defaultProps: { shrink: true },
          styleOverrides: {
            root: {
              ...typography.h5,
              color: palette.custom.black,
              marginLeft: -13,
            },
          },
        },
        MuiFormHelperText: { styleOverrides: { root: { marginLeft: 0 } } },
        MuiFormControl: { styleOverrides: { root: { marginBottom: 0 } } },
        MuiOutlinedInput: {
          defaultProps: { notched: false },
          styleOverrides: {
            notchedOutline: {
              borderRadius: 6,
              border: `1px solid ${palette.primary.K}`,
            },
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            popupIcon: (
              // eslint-disable-next-line react/react-in-jsx-scope
              <IcChevronDown
                style={{ width: 16, height: 16, strokeWidth: 2.5 }}
              />
            ),
          },
          styleOverrides: {
            inputRoot: { padding: '9px 12px 9px 12px' },
            input: { padding: '0 !important' },
            popper: {
              boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.15) !important',
            },
            popupIndicator: {
              backgroundColor: palette.primary.Q,
              borderRadius: 6,
              marginRight: 3,
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.15) !important',
            },
          },
        },
        MuiPaper: { styleOverrides: { rounded: { borderRadius: 6 } } },
        MuiSelect: {
          defaultProps: { IconComponent: IcChevronDown },
          styleOverrides: {
            icon: {
              height: 16,
              width: 16,
              strokeWidth: 2.5,
              backgroundColor: palette.primary.P,
              borderRadius: 6,
              marginRight: 5,
            },
          },
        },
        MuiListSubheader: {
          styleOverrides: {
            root: { ...typography.h6, color: palette.custom.black },
          },
        },
        MuiTooltip: {
          defaultProps: { arrow: true },
          styleOverrides: {
            tooltip: {
              backgroundColor: palette.primary.main,
              color: palette.custom.white,
              ...typography.body2,
            },
            arrow: { color: palette.primary.main },
          },
        },
        MuiSkeleton: {
          styleOverrides: {
            root: { backgroundColor: palette.primary.P, borderRadius: 6 },
          },
        },
        // Tables
        MuiTableCell: {
          styleOverrides: { root: { padding: '14px 0 16px 0' } },
        },
        MuiDialogTitle: {
          styleOverrides: { root: { ...typography.h4, padding: 24 } },
        },
        MuiDialogContent: {
          styleOverrides: { root: { padding: '0 24px 16px 24px' } },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              flexDirection: 'column',
              alignContent: 'stretch',
              columnGap: 0,
              rowGap: '8px',
              padding: '0 24px 24px 24px',
              '& > *': { margin: '0 !important' },
            },
          },
        },
        MuiAlert: {
          defaultProps: {
            iconMapping: {
              info: (
                // eslint-disable-next-line react/react-in-jsx-scope
                <IcActionsInfo
                  style={{
                    height: 24,
                    width: 24,
                    stroke: palette.custom.white,
                  }}
                />
              ),
              error: (
                // eslint-disable-next-line react/react-in-jsx-scope
                <IcActionsInfo
                  style={{
                    height: 24,
                    width: 24,
                    stroke: palette.custom.black,
                  }}
                />
              ),
            },
          },
          styleOverrides: {
            standardInfo: {
              backgroundColor: palette.info.main,
              color: palette.custom.white,
            },
            standardError: {
              backgroundColor: palette.error.main,
              color: palette.custom.black,
            },
            standardSuccess: {
              backgroundColor: palette.success.main,
              color: palette.custom.white,
            },
            icon: { alignItems: 'center', justifyContent: 'center' },
            message: { ...typography.body1 },
            action: { paddingTop: 3 },
          },
        },
        MuiAlertTitle: { styleOverrides: { root: { ...typography.h4 } } },
        MuiSnackbarContent: {
          styleOverrides: { root: { backgroundColor: palette.primary.main } },
        },
        MuiBackdrop: {
          styleOverrides: {
            root: { backgroundColor: 'rgba(0, 0, 0, 0.25)' },
            invisible: { backgroundColor: 'transparent !important' },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paperAnchorBottom: {
              borderTopRightRadius: 6,
              borderTopLeftRadius: 6,
              padding: '24px 16px 24px 16px',
              backgroundColor: palette.custom.white,
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: 0,
              '& .MuiCheckbox-root': { paddingTop: 0, paddingBottom: 0 },
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              padding: '6px 10px 6px 10px',
              borderRadius: 6,
              '&:hover': { backgroundColor: palette.primary.Q },
            },
          },
        },
        MuiListItemSecondaryAction: {
          styleOverrides: {
            root: { right: 10, display: 'flex', justifyContent: 'center' },
          },
        },
        MuiCheckbox: {
          defaultProps: {
            icon: <IcActionsMulti style={{ height: 20, width: 20 }} />,
            checkedIcon: (
              <IcActionsMultiSuccess style={{ height: 20, width: 20 }} />
            ),
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              margin: '2px 8px',
              padding: '6px 8px',
              borderRadius: 6,
              '&:hover': { backgroundColor: 'primary.Q' },
            },
          },
        },
        MuiPaginationItem: {
          styleOverrides: {
            text: typography.h6,
            root: {
              '&.Mui-selected': {
                backgroundColor: palette.primary.P,
                '&:hover': { backgroundColor: palette.primary.O },
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              paddingLeft: 8,
              paddingRight: 8,
            },
          },
        },
      },
    },
    locale === locals.ger.key ? deDE : enUS
  );
};
