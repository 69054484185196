import React from 'react';

import CTextField from './structure/TextField';

const FormikTextField = ({
  props,
  name,
  placeholder,
  disabled,
  isTouched,
  value,
  ...other
}) => {
  if (isTouched == null) isTouched = props.touched[name];
  return (
    <CTextField
      fullWidth
      margin="dense"
      variant="outlined"
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      value={value ?? props.values[name]}
      error={props.errors[name] && isTouched}
      helperText={
        (props.errors[name] && isTouched && props.errors[name]) || undefined
      }
      placeholder={disabled ? undefined : placeholder}
      disabled={disabled}
      name={name}
      {...other}
    />
  );
};
export default FormikTextField;
