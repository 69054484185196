import { useTranslation } from 'next-i18next';
import React from 'react';

import { ROUTES } from '../../utils/AppRoutes';
import LargeWhiteButton from './LargeWhiteButton';

function LoginButton({ fullWidth, label, sx }) {
  const { t } = useTranslation('common');

  return (
    <LargeWhiteButton
      label={label ? label : t('Login')}
      sx={sx}
      fullWidth={fullWidth}
      href={ROUTES.LOGIN}
    />
  );
}

export default LoginButton;
