export const ROUTES = {
  // Private Pages, only acessible after login
  DASHBOARD: '/dashboard',
  TRANSACTIONS: '/transactions',
  WALLETS: '/wallets',
  REPORTS: '/reports',
  AFFILIATE: '/affiliate',
  SUPPORT: '/support',

  // Public Pages
  HOME: '/',
  LOGIN: '/sign-in',
  SIGNUP: '/sign-up',
  EXCHANGES: '/exchanges',
  IMPRINT: '/imprint',
  AGB: '/terms',
  PRIVACY: '/privacy',
};

export const PRIVATE_ROUTES = [
  ROUTES.DASHBOARD,
  ROUTES.TRANSACTIONS,
  ROUTES.WALLETS,
  ROUTES.REPORTS,
  ROUTES.AFFILIATE,
  ROUTES.SUPPORT,
  '/wallets/[_id]',
];

export const getPublicPageStatus = (route) =>
  !PRIVATE_ROUTES.some((privateRoute) => route.startsWith(privateRoute));

export function getWalletRoute(wallet) {
  return wallet.parentWallet
    ? `${ROUTES.WALLETS}?id=${wallet.parentWallet._id}&subId=${wallet._id}`
    : `${ROUTES.WALLETS}?id=${wallet._id}`;
}
