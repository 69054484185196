import { styled } from '@mui/material';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

export const SnackbarProvider = styled(NotistackSnackbarProvider)(
  ({ theme }) => `  
    &.SnackbarItem-variantSuccess {
      background-color: ${theme.palette.primary.main};
      font-color: ${theme.palette.primary.Q}
    }

    &.SnackbarItem-variantWarning {
      background-color: ${theme.palette.secondary.main};
    }

    &.SnackbarItem-variantError {
      background-color: ${theme.palette.error.main};
    }

    &.SnackbarItem-variantInfo {
      background-color: ${theme.palette.info.main};
    } 
  `
);
