import MuiIconButton from '@mui/material/IconButton';
import React from 'react';

import Spinner from '../media/spinner.svg';

const IconButton = ({ loading = false, disabled, children, ...other }) => {
  return (
    <MuiIconButton disabled={loading || disabled} {...other}>
      {loading ? (
        <Spinner style={{ height: 24, width: 24, margin: -5 }} />
      ) : (
        children
      )}
    </MuiIconButton>
  );
};

export default IconButton;
