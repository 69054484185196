import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Alert, AlertTitle, Grow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

import errorCodes, {
  ERROR_CHANGE_EMAIL,
  ERROR_EMAIL_USED,
} from '@cr/common/src/config/error-codes';
import { areRequired, isEmailValid } from '@cr/common/src/validation';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import { FormTextfield } from '../../utils/styles/form.styles';
import { UsersContext } from '../hooks/useUser';
import AccentButton from '../structure/AccentButton';

const CHANGE_EMAIL = gql`
  mutation changeEmail($email: Email!) {
    changeEmail(newEmail: $email)
  }
`;

const RESEND_VERIFICATION = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail
  }
`;

const ChangeEmail = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [changeEmail, { data, loading }] = useMutation(CHANGE_EMAIL, {
    onCompleted: () =>
      enqueueSnackbar(
        t(
          'Die Änderung Deiner E-Mail war erfolgreich. Bitte verifiziere Deine neue E-Mail-Adresse.'
        ),
        { variant: 'success' }
      ),
  });
  const [resendVerification, resendData] = useMutation(RESEND_VERIFICATION, {
    onCompleted: () =>
      enqueueSnackbar(t('E-Mail erfolgreich versendet.'), {
        variant: 'success',
      }),
  });
  const { user } = useContext(UsersContext);

  const defaultState = {
    email: user ? user.email.current : '',
    revalidateEmail: '',
  };

  const formik = useFormik({
    onSubmit: async ({ email }, { setFieldError, resetForm }) => {
      try {
        const { errors } = await changeEmail({
          variables: { email },
          update: (cache, { data: { changeEmail } }) => {
            if (!changeEmail) return;
            cache.modify({
              id: cache.identify(user),
              fields: {
                email: () => ({
                  isVerified: false,
                  current: email,
                }),
              },
            });
          },
        });

        if (!errors) resetForm(defaultState);
      } catch (e) {
        const error = e.graphQLErrors[0];
        switch (error.message) {
          case errorCodes.email.inUse.message:
            return setFieldError(
              'email',
              t('Diese E-Mail-Adresse wird bereits verwendet.')
            );
          default:
            showDefaultErrorSnackbarMessage({
              t,
              enqueueSnackbar,
              code: ERROR_EMAIL_USED,
            });
        }
      }
    },
    validate: (values) => {
      const errors = {};
      if (!isEmailValid(values.email))
        errors.email = t('Ungültige E-Mail-Adresse.');
      if (values.email === user.email.current) errors.email = t('Unverändert.');
      areRequired(values, errors, ['email', 'revalidateEmail'], t);
      if (values.email !== values.revalidateEmail)
        errors.revalidateEmail = t('Die neue E-Mail stimmt nicht überein.');
      return errors;
    },
    initialValues: defaultState,
    enableReinitialize: true,
  });

  if (!user) return null;
  return (
    <>
      <Typography variant="h4">{t('common:E-Mail')}</Typography>

      {!user.email.isVerified && (
        <Alert severity="info">
          <AlertTitle>{t('Fehlende Verifizierung.')}</AlertTitle>
          {t(
            'Bitte verifiziere Deine E-Mail-Adresse um die Sicherheit des Kontos zu gewährleisten und Käufe zu ermöglichen.'
          )}
          <Grow in={!resendData.loading && !resendData.data}>
            <Box sx={{ pt: 1 }}>
              <AccentButton
                isLoadingButton
                loading={resendData.loading}
                color="info"
                variant="contained"
                size="small"
                onClick={async () => {
                  try {
                    await resendVerification();
                  } catch (e) {
                    const error = e.graphQLErrors[0];
                    switch (error.message) {
                      case errorCodes.email.sentAllready.message:
                        enqueueSnackbar(
                          t(
                            'Die Verifizierung wurde bereits innerhalb der letzten 10 Minuten gesendet. Bitte überprüfe Deine angegebene E-Mail, Dein Postfach oder versuche es in ein paar Minuten erneut.'
                          ),
                          { variant: 'error' }
                        );
                        break;
                      default:
                        showDefaultErrorSnackbarMessage({
                          t,
                          enqueueSnackbar,
                          code: ERROR_CHANGE_EMAIL,
                        });
                    }
                  }
                }}
              >
                {!resendData.error && !!resendData.data
                  ? t('common:Gesendet')
                  : t('Verifizierungs-E-Mail erneut versenden')}
              </AccentButton>
            </Box>
          </Grow>
        </Alert>
      )}

      <form onSubmit={formik.handleSubmit}>
        <FormTextfield name="email" label={t('common:E-Mail')} props={formik} />

        <FormTextfield
          name="revalidateEmail"
          label={t('Neue E-Mail bestätigen')}
          props={formik}
        />

        <AccentButton
          type="submit"
          isLoadingButton
          loading={loading}
          disabled={!(formik.isValid && formik.dirty)}
          size="large"
          fullWidth
        >
          {data ? t('common:Gespeichert') : t('common:Ändern')}
        </AccentButton>
      </form>
    </>
  );
};
export default ChangeEmail;
