import Logo from '../media/icons/chain-report-brand-colored.svg';

const Maintenance = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: '#F8F8F8',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <Logo style={{ height: '50px', marginBottom: '30px' }} />
    <span
      style={{
        fontFamily: '"Inter", sans-serif',
        marginBottom: '10px',
      }}
    >
      <b>Maintenance</b>, we will be back soon.
    </span>
  </div>
);

export default Maintenance;
