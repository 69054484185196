import {
  AppBar,
  Avatar,
  Badge,
  Skeleton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';

import Logo from '../../media/icons/chain-report-brand-colored.svg';
import IcActionsNew from '../../media/icons/ic-actions-new-window.svg';
import IcContactChat from '../../media/icons/ic-contact-chat.svg';
import LogoDark from '../../media/images/LogoDark.svg';
import LogoLight from '../../media/images/LogoLight.svg';
import { ROUTES, getPublicPageStatus } from '../../utils/AppRoutes';
import ChangeLanguage from '../ChangeLanguage';
import { SettingsContext } from '../hooks/useSettings';
import { UsersContext } from '../hooks/useUser';
import IconButton from '../IconButton';
import Link from '../Link';
import AccentButton from './AccentButton';
import LoginButton from './LoginButton';
import SignUpButton from './SignUpButton';
import TabMenu from './TabMenu';

export const HeaderMobile = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const router = useRouter();
  const isPublicPage = getPublicPageStatus(router.pathname);

  if (isPublicPage || (!isMobile && !isTablet)) return null;
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: ({ palette }) => palette.common.white,
        width: '100%',
        color: ({ palette }) => palette.primary.main,
        top: 'auto',
        bottom: 0,
        height: 64,
        borderTop: ({ palette }) => `1px solid ${palette.primary.O}`,
      }}
    >
      <Toolbar sx={{ height: '100%' }}>
        <TabMenu isDesktop={false} isMobile={isMobile} />
      </Toolbar>
    </AppBar>
  );
};

const Header = () => {
  const [isClient, setIsClient] = useState(false);
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const router = useRouter();
  const isPublicPage = getPublicPageStatus(router.pathname);

  const { user, isLightVariant, loading, isImpersonate, goBackToPrevUser } =
    useContext(UsersContext);
  const { setOpen: setSettingsOpen } = useContext(SettingsContext);

  const [isIntercomShown, setIsIntercomShown] = useState(false);

  // Fixes Hydration/SSR errors as the user is being loaded
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!window.Intercom) return;

    /* eslint-disable-next-line */
    window.Intercom('onHide', () => {
      setIsIntercomShown(false);
    });
    /* eslint-disable-next-line */
    window.Intercom('onShow', () => {
      setIsIntercomShown(true);
    });
  }, []);
  const handleContactSupport = () => {
    /* eslint-disable-next-line */
    if (window.Intercom) window.Intercom(isIntercomShown ? 'hide' : 'show');
  };

  const userButton = (
    <AccentButton
      variant="text"
      size="small"
      sx={{
        color: isLightVariant ? 'custom.black' : 'custom.white',
        padding: isDesktop ? '11px' : '11px 8px',
      }}
      onClick={!isPublicPage ? () => setSettingsOpen(true) : undefined}
      href={isPublicPage ? ROUTES.DASHBOARD : undefined}
      startIcon={
        !user ? (
          <Skeleton
            variant="circular"
            animation={false}
            height={24}
            width={24}
          />
        ) : !user?.email?.isVerified ? (
          <Badge
            variant="dot"
            color="error"
            sx={{ '& .MuiBadge-badge': { right: '5px' } }}
          >
            <AvatarDisplay firstname={user.address.firstname} />
          </Badge>
        ) : (
          <AvatarDisplay firstname={user.address.firstname} />
        )
      }
    >
      {user ? (
        <Typography variant={isDesktop ? 'h6' : 'h5'}>
          {user.address.firstname}
        </Typography>
      ) : (
        <Skeleton
          variant="rectangular"
          animation={false}
          height={20.8}
          width={70}
        />
      )}
    </AccentButton>
  );

  if (!isDesktop && !isPublicPage)
    return (
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'custom.background',
          width: '100%',
          height: 64,
          color: 'primary.main',
          padding: '0 16px',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item />
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0.5}
            >
              <Grid item>
                <IconButton
                  aria-label={t('Support')}
                  onClick={handleContactSupport}
                >
                  <IcContactChat style={{ width: 24, height: 24 }} />
                </IconButton>
              </Grid>

              <Grid item>{userButton}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: isLightVariant ? 'custom.white' : 'custom.black',
        width: '100%',
        color: 'primary.main',
        borderBottom: ({ palette }) => `1px solid ${palette.primary.O}`,
      }}
    >
      <Toolbar sx={{ height: isMobile && isPublicPage ? 96 : 64 }}>
        <Grid
          container
          sx={{ width: '100%' }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          {isMobile && (
            <Grid
              item
              style={{
                height: 32,
                marginLeft: -16,
                marginTop: -12,
                marginBottom: 12,
              }}
            >
              <ChangeLanguage
                variant="select-fullwidth"
                color={isLightVariant ? 'light' : 'dark'}
              />
            </Grid>
          )}

          <Grid item xs={isPublicPage ? 6 : 2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Link href={isPublicPage ? ROUTES.HOME : ROUTES.DASHBOARD}>
                  <Box sx={{ height: 28 }}>
                    {isPublicPage ? (
                      <>
                        {isLightVariant ? (
                          <LogoDark style={{ height: '100%' }} />
                        ) : (
                          <LogoLight style={{ height: '100%' }} />
                        )}
                      </>
                    ) : (
                      <Logo style={{ height: '100%' }} />
                    )}
                  </Box>
                </Link>
              </Grid>

              {!isMobile && !user && !loading && isClient && (
                <Grid item>
                  <ChangeLanguage
                    variant="select-small"
                    color={isLightVariant ? 'light' : 'dark'}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {!isPublicPage && (
            <Grid item>
              <Toolbar
                sx={{
                  minHeight: 0,
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
              >
                <TabMenu isDesktop />
              </Toolbar>
            </Grid>
          )}

          {isClient && (
            <Grid item xs={isPublicPage ? 6 : 2}>
              <Box
                sx={{
                  position: 'relative',
                  height: user || !isPublicPage ? '46px' : '40px',
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="0.5rem"
                  sx={{ width: '100%' }}
                >
                  {loading && isPublicPage ? (
                    <>
                      <Grid item>
                        <Skeleton
                          variant="rectangular"
                          animation={false}
                          height={40}
                          width={110}
                        />
                      </Grid>
                      {!isMobile && (
                        <Grid item>
                          <Skeleton
                            variant="rectangular"
                            animation={false}
                            height={40}
                            width={196}
                          />
                        </Grid>
                      )}
                    </>
                  ) : (user && !user?.isDemo) || !isPublicPage ? (
                    <>
                      {user?.isDemo ? (
                        <Grid item>
                          <SignUpButton
                            sx={{
                              height: 40,
                              margin: 0.35,
                              color: isLightVariant
                                ? 'custom.white'
                                : 'custom.black',
                            }}
                            color={isLightVariant ? 'primary' : 'secondary'}
                            label={t('Kostenlos starten')}
                          />
                        </Grid>
                      ) : (
                        <>
                          <Grid item>
                            {isImpersonate ? (
                              <IconButton
                                onClick={goBackToPrevUser}
                                color="error"
                              >
                                <IcActionsNew
                                  style={{ width: 24, height: 24 }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label={t('Support')}
                                onClick={handleContactSupport}
                              >
                                <IcContactChat
                                  style={{
                                    width: 24,
                                    height: 24,
                                    stroke: isLightVariant ? 'black' : 'white',
                                  }}
                                />
                              </IconButton>
                            )}
                          </Grid>

                          <Grid item>{userButton}</Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {router.asPath !== ROUTES.LOGIN && (
                        <Grid item>
                          <LoginButton />
                        </Grid>
                      )}

                      {(!isMobile && router.asPath !== ROUTES.SIGNUP) ||
                      router.asPath === ROUTES.LOGIN ? (
                        <Grid item>
                          <SignUpButton
                            sx={{
                              minWidth: 0,
                              height: 40,
                              margin: 0,
                              color: isLightVariant
                                ? 'custom.white'
                                : 'custom.black',
                            }}
                            color={isLightVariant ? 'primary' : 'secondary'}
                            label={t('Kostenlos starten')}
                          />
                        </Grid>
                      ) : null}
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default Header;

const AvatarDisplay = ({ firstname = '' }) => (
  <Avatar
    sx={{
      width: 24,
      height: 24,
      bgcolor: 'info.main',
      fontSize: '12px !important',
    }}
  >
    {firstname.slice(0, 1).toUpperCase()}
  </Avatar>
);
