import IcActionsListUsersActive from './media/icons/ic-actions-list-users-active.svg';
import IcActionsListUsers from './media/icons/ic-actions-list-users.svg';
import IcEcommerceInvoiceActive from './media/icons/ic-ecommerce-invoice-active.svg';
import IcEcommerceInvoice from './media/icons/ic-ecommerce-invoice.svg';
import IcEcommerceMoneyActive from './media/icons/ic-ecommerce-money-active.svg';
import IcEcommerceMoney from './media/icons/ic-ecommerce-money.svg';
import IcLayoutPictureRightActive from './media/icons/ic-layout-picture-right-active.svg';
import IcLayoutPictureRight from './media/icons/ic-layout-picture-right.svg';
import IcShoppingWalletActive from './media/icons/ic-shopping-wallet-active.svg';
import IcShoppingWallet from './media/icons/ic-shopping-wallet.svg';
import IcActionsAgent from './media/icons/ic-actions-support-agent.svg';
import IcActionsAgentActive from './media/icons/ic-actions-support-agent-active.svg';
import { palette } from './theme';
import { ROUTES } from './utils/AppRoutes';

export default [
  {
    name: 'Dashboard',
    path: ROUTES.DASHBOARD,
    icons: [IcLayoutPictureRight, IcLayoutPictureRightActive],
    color: palette.secondary.main,
  },
  {
    name: 'Transaktionen',
    path: ROUTES.TRANSACTIONS,
    icons: [IcEcommerceMoney, IcEcommerceMoneyActive],
    color: palette.success.main,
  },
  {
    name: 'Wallets',
    path: ROUTES.WALLETS,
    icons: [IcShoppingWallet, IcShoppingWalletActive],
    color: palette.info.main,
  },
  {
    name: 'Abschlüsse',
    path: ROUTES.REPORTS,
    icons: [IcEcommerceInvoice, IcEcommerceInvoiceActive],
    color: palette.error.main,
  },
  {
    name: 'Freunde',
    path: ROUTES.AFFILIATE,
    icons: [IcActionsListUsers, IcActionsListUsersActive],
    color: palette.secondary.main,
  },
  {
    name: 'Support',
    path: ROUTES.SUPPORT,
    icons: [IcActionsAgent, IcActionsAgentActive],
    color: palette.secondary.main,
  },
];
