import { Box, Select, styled } from '@mui/material';
import React from 'react';

import IcChevronDown from '../../media/icons/ic-chevron-down.svg';

/**
 *
 * @param {string} iconStroke color of the svg icon.
 */
const CSelect = ({ iconStroke, menuProps, ...props }) => {
  return (
    <Select
      disableUnderline
      variant="standard"
      sx={{
        backgroundColor: 'primary.P',
        borderRadius: 1,
        minWidth: '3.5rem',
        maxHeight: '2rem',
        ...props.sx,
      }}
      MenuProps={menuProps}
      IconComponent={(props) => (
        <Box
          sx={{
            height: '14px',
            width: '14px',
            borderRadius: 25,
            marginRight: '3px',
            display: 'grid',
            placeItems: 'center',
          }}
          style={{ backgroundColor: 'transparent' }}
          {...props}
        >
          <IcChevronDown style={{ stroke: iconStroke }} />
        </Box>
      )}
      {...props}
    />
  );
};

export default styled(CSelect)`
  & .MuiSelect-select {
    padding: 6px 12px !important;
    min-height: 0;
  }
`;
