import '../utils/styles/AppStyleOverrides.css';

import { ApolloProvider } from '@apollo/client';
import { LicenseInfo } from '@mui/x-license-pro';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import { appWithTranslation } from 'next-i18next';
import PlausibleProvider from 'next-plausible';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { defaults as chartDefaults } from 'react-chartjs-2';
import { CookiesProvider } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { locals } from '@cr/common/src/config/constants';
import { useApollo } from '../apollo';
import { SnackbarProvider } from '../components/context/SnackbarProvider';
import CookieDisclaimer from '../components/CookiesDisclaimer';
import { SettingsProvider } from '../components/hooks/useSettings';
import { TransactionFilterProvider } from '../components/hooks/useTransactionFilter';
import { UsersProvider } from '../components/hooks/useUser';
import Header, { HeaderMobile } from '../components/structure/Header';
import TransactionClearing from '../components/structure/TransactionClearing';
import { buildTheme } from '../theme';
import createEmotionCache from '../utils/createEmotionCache';
import Maintenance from '../components/Maintenance';

chartDefaults.global.defaultFontFamily = 'Inter';
const localeDe = moment.locale('de');
const localeEn = moment.locale('en');
LicenseInfo.setLicenseKey(
  'cde461e38715926bbb98e92e93fd9227Tz05NTUwNixFPTE3NTQzNDM2MTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
);
const { publicRuntimeConfig } = getConfig();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const App = ({
  Component,
  pageProps,
  err,
  emotionCache = clientSideEmotionCache,
}) => {
  const router = useRouter();

  const routerLocale = useMemo(() => router.locale, [router.locale]);
  const dateLocale = useMemo(
    () => (routerLocale === locals.ger.key ? localeDe : localeEn),
    [routerLocale]
  );
  const theme = useMemo(() => buildTheme(routerLocale), [routerLocale]);

  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) jssStyles.parentElement.removeChild(jssStyles);
  }, []);

  if (publicRuntimeConfig.maintenance === 'true') return <Maintenance />;
  return (
    <PlausibleProvider
      domain="chain.report"
      enabled={publicRuntimeConfig.stage === 'production'}
    >
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Crypto tax report within minutes.</title>
          <meta
            key="desc"
            name="description"
            content="Chain.report is a crypto tax reporting tool that allows you to create a crypto tax report within minutes. All exchanges, exchanges and wallets (e.g. Bitcoin and Ethereum) can be synchronised with just a few clicks."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Head>

        <GoogleReCaptchaProvider
          reCaptchaKey={publicRuntimeConfig.recaptchaKey}
          language={router.locale}
          scriptProps={{ async: true, defer: true, appendTo: 'body' }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <SnackbarProvider
              maxSnack={5}
              dense
              hideIconVariant
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <ApolloProvider client={apolloClient}>
                <LocalizationProvider
                  adapterLocale={dateLocale}
                  dateAdapter={AdapterMoment}
                >
                  <UsersProvider>
                    <TransactionFilterProvider>
                      <SettingsProvider>
                        <CookiesProvider>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              minHeight: '100vh',
                              maxWidth: '100%',
                              backgroundColor: ({ palette }) =>
                                palette.custom.background,
                            }}
                          >
                            <Header />

                            <Component
                              {...pageProps}
                              key={router.route}
                              err={err}
                            />

                            <HeaderMobile />
                            <TransactionClearing />
                          </Box>

                          <CookieDisclaimer />
                        </CookiesProvider>
                      </SettingsProvider>
                    </TransactionFilterProvider>
                  </UsersProvider>
                </LocalizationProvider>
              </ApolloProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </CacheProvider>
    </PlausibleProvider>
  );
};

export default appWithTranslation(App);
