import { gql, useMutation } from '@apollo/client';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

import errorCodes, {
  ERROR_ACCOUNT_REMOVAL,
} from '@cr/common/src/config/error-codes';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import { UsersContext } from '../hooks/useUser';
import AccentButton from '../structure/AccentButton';

const REQUEST_ACCOUNT_REMOVAL = gql`
  mutation requestUserRemoval {
    requestUserRemoval
  }
`;

function RequestAccountRemoval() {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [requestRemoval, result] = useMutation(REQUEST_ACCOUNT_REMOVAL, {
    onCompleted: () =>
      enqueueSnackbar(
        t('Bitte überprüfe Dein Postfach, um die Löschung abzuschließen.'),
        { variant: 'success' }
      ),
    onError: (error) => {
      switch (error.message) {
        case errorCodes.email.sentAllready.message:
          enqueueSnackbar(
            t('Die E-Mail wurde in den letzten 10 Minuten gesendet.'),
            { variant: 'error' }
          );
          break;
        default:
          showDefaultErrorSnackbarMessage({
            t,
            enqueueSnackbar,
            code: ERROR_ACCOUNT_REMOVAL,
          });
          break;
      }
    },
  });
  const { user } = useContext(UsersContext);

  return (
    <>
      <Typography variant="h4">{t('Account löschen')}</Typography>
      <Typography variant="subtitle1" sx={{ color: 'primary.L' }} paragraph>
        {t(
          'Hier kannst Du Deinen Account nach einer Verifizierung dauerhaft löschen.'
        )}
      </Typography>

      {user?.email?.isVerified ? (
        <AccentButton
          isLoadingButton
          color="warning"
          loading={result.loading}
          onClick={requestRemoval}
          size="large"
          fullWidth
        >
          {result?.data?.requestUserRemoval
            ? t('E-Mail gesendet')
            : t('Account löschen')}
        </AccentButton>
      ) : (
        <Tooltip
          title={t(
            'Du musst Deine E-Mail-Adresse bestätigen bevor Du Dein Konto löschen kannst.'
          )}
        >
          <div>
            <AccentButton color="warning" size="large" fullWidth disabled>
              {t('Account löschen')}
            </AccentButton>
          </div>
        </Tooltip>
      )}
    </>
  );
}

export default RequestAccountRemoval;
