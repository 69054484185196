import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';

import { ROUTES } from '../../utils/AppRoutes';
import AccentButton from './AccentButton';
import { UsersContext } from '../hooks/useUser';

function LargeWhiteButton({ fullWidth, label, sx, ...props }) {
  const { isLightVariant = false } = useContext(UsersContext);

  const hoverStyle = !isLightVariant
    ? {
        bgcolor: 'custom.white',
        color: 'custom.black',
      }
    : {};
  return (
    <AccentButton
      color={isLightVariant ? 'secondary' : 'primary'}
      size="large"
      sx={{
        backgroundColor: isLightVariant && 'custom.white',
        height: 40,
        border: '2px solid transparent',
        ':hover': hoverStyle && hoverStyle,
        borderColor: isLightVariant ? 'primary.N' : 'secondary.main',
        color: isLightVariant ? 'custom.black' : 'custom.white',
        ...sx,
      }}
      fullWidth={fullWidth}
      {...props}
    >
      <Typography fontWeight="bold">{label}</Typography>
    </AccentButton>
  );
}

export default LargeWhiteButton;
