import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';

import errorCodes, {
  ERROR_CHANGE_FIRSTNAME,
} from '@cr/common/src/config/error-codes';
import { areRequired, isValidFirstname } from '@cr/common/src/validation';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import { FormTextfield } from '../../utils/styles/form.styles';
import { UsersContext } from '../hooks/useUser';
import AccentButton from '../structure/AccentButton';

const CHANGE_FIRSTNAME = gql`
  mutation changeFirstname($firstname: String!) {
    changeFirstname(newFirstname: $firstname)
  }
`;

function ChangeFirstname() {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [changeFirstname, { data, loading }] = useMutation(CHANGE_FIRSTNAME, {
    onCompleted: () =>
      enqueueSnackbar(t('Die Änderung Deines Nutzernamens war erfolgreich.'), {
        variant: 'success',
      }),
  });
  const { user } = useContext(UsersContext);

  const defaultState = {
    firstname: user?.address?.firstname ?? '',
  };

  const formik = useFormik({
    onSubmit: async ({ firstname }, { setFieldError, resetForm }) => {
      try {
        const { errors } = await changeFirstname({
          variables: { firstname },
          update: (cache, { data: { changeFirstname } }) => {
            if (!changeFirstname) return;
            cache.modify({
              id: cache.identify(user),
              fields: {
                address: (cached) => ({
                  ...cached,
                  firstname,
                }),
              },
            });
          },
        });

        if (!errors) resetForm(defaultState);
      } catch (e) {
        const error = e.graphQLErrors[0];
        switch (error.message) {
          case errorCodes.settings.firstname.invalid.message:
            return setFieldError(
              'firstname',
              t('Vorname ist zu kurz oder ungültig.')
            );
          case errorCodes.settings.firstname.unchanged.message:
            return setFieldError('firstname', t('Unverändert.'));
          default:
            showDefaultErrorSnackbarMessage({
              t,
              enqueueSnackbar,
              code: ERROR_CHANGE_FIRSTNAME,
            });
        }
      }
    },
    validate: ({ firstname }) => {
      const errors = {};
      if (!isValidFirstname(firstname))
        errors.firstname = t('Vorname ist zu kurz oder ungültig.');
      if (firstname === user.firstname) errors.email = t('Unverändert.');
      areRequired({ firstname }, errors, ['firstname'], t);
      return errors;
    },
    initialValues: defaultState,
    enableReinitialize: true,
  });

  if (!user) return null;

  return (
    <>
      <Typography variant="h4">{t('Persönlich')}</Typography>

      <form onSubmit={formik.handleSubmit}>
        <FormTextfield name="firstname" label={t('Vorname')} props={formik} />

        <AccentButton
          type="submit"
          isLoadingButton
          loading={loading}
          disabled={!(formik.isValid && formik.dirty)}
          size="large"
          fullWidth
        >
          {data ? t('Gespeichert') : t('Ändern')}
        </AccentButton>
      </form>
    </>
  );
}

export default ChangeFirstname;
