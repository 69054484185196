export default {
  taxCalc: {
    assetConvOverUsage: 'assetConvOverUsage',
  },
  email: {
    inUse: {
      message: 'email already in use',
    },
    unchanged: {
      message: 'email unchanged',
    },
    sentAllready: {
      message: 'already send before <10min',
    },
    notVerified: {
      message: 'email not verified',
    },
  },
  auth: {
    wrongCredentials: {
      message: 'wrong credentials',
    },
    affiliateNonExistent: {
      message: 'the affiliated user doesnt exist',
    },
    affiliateCodeInsufficient: {
      message: 'the provided id doesnt fulfill the criteria',
    },
    badToken: {
      message: 'bad token',
    },
    outdatedToken: {
      message: 'token is outdated',
    },
    recaptchaFailed: {
      message: 'recaptcha has been failed or was not provided',
    },
  },
  settings: {
    firstname: {
      unchanged: {
        message: 'firstname is unchanged',
      },
      invalid: {
        message: 'firstname contains invalid characters or is too short',
      },
    },
  },
  transactions: {
    emptyStatement: {
      message: 'empty statement',
    },
    sheetError: {
      message: 'sheetError',
    },
  },
  assets: {
    symbolInUse: {
      message: 'symbol already in use',
    },
  },
};

export const ERROR_CREATE_DEMO = 50001;
export const ERROR_SYNC_API = 50002;
export const ERROR_BUY_TAX_YEAR = 50003;
export const ERROR_GENERATE_CSV = 50004;
export const ERROR_GET_UNPAID_WALLET_YEARS = 50005;
export const ERROR_GENERATE_WALLET_CSV = 50006;
export const ERROR_GENERATE_REPORT = 50007;
export const ERROR_BUY_YEAR = 50008;
export const ERROR_CHANGE_EMAIL = 50009;
export const ERROR_EMAIL_USED = 50010;
export const ERROR_CHANGE_FIRSTNAME = 50011;
export const ERROR_CHANGE_PASSWORD = 50012;
export const ERROR_ACCOUNT_REMOVAL = 50013;
export const ERROR_CREATE_ASSET = 50014;
export const ERROR_REMOVE_TRANSACTION = 50015;
export const ERROR_TRANSACTION_ROW = 50016;
export const ERROR_DEFINE_TRANSFER = 50017;
export const ERROR_PROHIBIT_TRANSFER = 50018;
export const ERROR_OVERWRITE_PRICE = 50019;
export const ERROR_MARK_AS_PAYMENT = 50020;
export const ERROR_NOTICE_WARNINGS = 50021;
export const ERROR_MARK_AS_X = 50022;
export const ERROR_CREATE_TRANSACTION = 50023;
export const ERROR_REMOVE_API = 50024;
export const ERROR_RE_SYNC_API = 50025;
export const ERROR_DELETE_STATEMENT = 50026;
export const ERROR_CREATE_WALLET = 50027;
export const ERROR_UPDATE_WALLET = 50028;
export const ERROR_DELETE_WALLET = 50029;
export const ERROR_DELETE_ACCOUNT = 50030;
export const ERROR_GET_TAX_YEARS = 50031;
export const ERROR_SEND_PASSWORD_RESET_EMAIL = 50032;
export const ERROR_LOGIN = 50033;
export const ERROR_REGISTER = 50034;
export const ERROR_RECAPTCHA = 50035;
export const ERROR_SIGNUP = 50036;
export const ERROR_HAS_PAID_WALLET_TRANSACTIONS = 50037;
