export const showDefaultErrorSnackbarMessage = ({
  t,
  code,
  enqueueSnackbar,
}) => {
  let errorMessage = t('Ein unbekannter Fehler ist aufgetreten.', {
    ns: 'common',
  });
  if (code) {
    errorMessage += ' code: ' + code;
  }
  enqueueSnackbar(errorMessage, {
    variant: 'error',
  });
};
