import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import React from 'react';
import Link from '../Link';

function AccentButton({ children, href, ...buttonProps }) {
  if (href)
    return (
      <Link href={href} passHref draggable={false}>
        <DynamicButton {...buttonProps}>{children}</DynamicButton>
      </Link>
    );
  return <DynamicButton {...buttonProps}>{children}</DynamicButton>;
}

function DynamicButton({ isLoadingButton, children, ...buttonProps }) {
  if (isLoadingButton)
    return (
      <LoadingButton variant="contained" {...buttonProps}>
        {children}
      </LoadingButton>
    );

  return (
    <Button variant="contained" {...buttonProps}>
      {children}
    </Button>
  );
}

export default AccentButton;
