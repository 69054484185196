import { styled } from '@mui/material';
import React from 'react';

import FormikTextField from '../../components/FormikTextField';

const Textfield = styled(FormikTextField)`
  margin-bottom: 1.5rem;
`;

export const FormTextfield = ({ ...props }) => (
  <Textfield variant="outlined" fullWidth {...props} />
);
