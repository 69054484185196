import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import React from 'react';

import errorCodes, {
  ERROR_CHANGE_PASSWORD,
} from '@cr/common/src/config/error-codes';
import { areRequired, checkPasswordLength } from '@cr/common/src/validation';
import { showDefaultErrorSnackbarMessage } from '../../utils/showDefaultErrorSnackbarMessage';
import { FormTextfield } from '../../utils/styles/form.styles';
import AccentButton from '../structure/AccentButton';

const CHANGE_PASSWORD = gql`
  mutation changePassword($newPassword: Password!, $oldPassword: Password!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword)
  }
`;

const ChangePassword = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [changePassword, { data, loading = false }] =
    useMutation(CHANGE_PASSWORD);
  const formik = useFormik({
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {
        await changePassword({
          variables: {
            newPassword: values.newPasswordA,
            oldPassword: values.oldPassword,
          },
        });
        resetForm();
        enqueueSnackbar(t('Passwort erfolgreich geändert.'), {
          variant: 'success',
        });
      } catch (e) {
        switch (e.graphQLErrors[0].message) {
          case errorCodes.auth.wrongCredentials.message:
            setFieldError('oldPassword', 'Fehlerhaftes Passwort.');
            break;
          default:
            showDefaultErrorSnackbarMessage({
              t,
              enqueueSnackbar,
              code: ERROR_CHANGE_PASSWORD,
            });
        }
      }
    },
    validate: (values) => {
      const errors = {};
      checkPasswordLength(values.oldPassword, errors, t);
      checkPasswordLength(values.newPasswordA, errors, t);
      areRequired(
        values,
        errors,
        ['oldPassword', 'newPasswordA', 'newPasswordB'],
        t
      );
      if (
        values.newPasswordA &&
        values.newPasswordB &&
        values.newPasswordA !== values.newPasswordB
      )
        errors.newPasswordB = t('Das Passwort stimmt nicht überein.');
      return errors;
    },
    initialValues: {
      oldPassword: '',
      newPasswordA: '',
      newPasswordB: '',
    },
  });

  return (
    <>
      <Typography variant="h4">Passwort ändern</Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormTextfield
          type="password"
          name="oldPassword"
          label={t('Altes Passwort')}
          props={formik}
        />
        <FormTextfield
          type="password"
          name="newPasswordA"
          label={t('Neues Passwort')}
          props={formik}
        />
        <FormTextfield
          type="password"
          name="newPasswordB"
          label={t('Wiederholung')}
          props={formik}
        />

        <AccentButton
          isLoadingButton
          type="submit"
          loading={loading}
          disabled={!(formik.isValid && formik.dirty)}
          size="large"
          fullWidth
        >
          {data ? t('common:Gespeichert') : t('common:Ändern')}
        </AccentButton>
      </form>
    </>
  );
};
export default ChangePassword;
